<template>
  <div class="container--fluid fill-height">
    <TheLayoutPageTitle :title="$t('route.portal.aerodromo.title')" />
    <v-container>
      <v-card
        outlined
        rounded="xl"
        class="pa-4"
      >
        <v-row>
          <v-col>
            <v-card flat>
              <v-card-title>
                <h3 class="color-blue mb-6">
                  {{ $t('aerodromo.cardInscricao.titulo') }}
                </h3>
              </v-card-title>
              <v-card-subtitle>
                {{ $t('inscricao.subtitulo') }}
              </v-card-subtitle>
              <v-card-text>
                <p class="text-justify">
                  {{ $t('inscricao.texto') }}
                </p>
              </v-card-text>
              <v-card-text class="text-right">
                <v-btn
                  class="primary"
                  depressed
                  target="_blank"
                  href="https://sysaga2.decea.mil.br/"
                >
                  {{ $t('button.openProcess') }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider vertical />
          <v-col>
            <v-card flat>
              <v-card-title>
                <h2 class="text-h4">
                  {{ $t('inscricao.cardDocumentacao.titulo') }}
                </h2>
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item-group v-model="item">
                    <div
                      v-for="arquivo in arquivos"
                      :key="arquivo.id"
                    >
                      <v-list-item
                        two-line
                        :href="arquivo.link_portal || arquivo.link"
                        target="self"
                      >
                        <v-list-item-content>
                          <v-list-item-title class="color-blue font-weight-light">
                            {{ arquivo.text_en || arquivo.text_es || arquivo.text_pt }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ arquivo.subtext_en || arquivo.subtext_es || arquivo.subtext_pt }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import TheLayoutPageTitle from '../layout/TheLayoutPageTitle.vue';

export default {
  name: 'AerodromoInscricao',
  components: { TheLayoutPageTitle },
  data: () => ({
    item: '',
    arquivos: [
    ],
    justify: [
      'center',
    ],
  }),
  mounted() {
    // TODO: Descomentar quando tiver dados no banco.
    this.buscandoDocumentos();
  },
  methods: {
    async buscandoDocumentos() {
      const parametrosBusca = {
        status: 'Ativado',
        tipo: 'ProjetoAerodromoInscrição',
        idioma: this.$i18n.locale,
      };
      try {
        const apiurl = `${process.env.VUE_APP_URL_API}/portal/downloads`;
        await this.$axios.post(apiurl, parametrosBusca)
          // eslint-disable-next-line max-len
          // await this.$axios.post(`${process.env.VUE_APP_URL_API}/portal/downloads`,
          // parametrosBusca)
          .then((response) => {
            this.arquivos = response.data.data.filter((arquivos) => arquivos.tipo === 'ProjetoAerodromoInscrição' && arquivos.status === 'Aprovado');
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.warn('buscandoDocumentos', err);
      }
    },
  },
};
</script>
